// モジュールをインポート
import { faq } from "./modules/faq.js";
import { modal } from "./modules/modal.js";
import { modalSS } from "./modules/modalSS.js";
// import { popUp } from "./modules/popUp.js";
import { fadeAnime } from "./modules/fadeAnime.js";
import { selectEmpty } from "./modules/selectEmpty.js";
import { tabs } from "./modules/tab.js";
import { toast } from "./modules/toast.js";
import { toTabTop } from "./modules/toTabTop.js";
import { toTop } from "./modules/toTop.js";

// 関数を実行
faq();
modal();
modalSS();
// popUp();
fadeAnime();
selectEmpty();
tabs();
toast();
toTabTop();
toTop();
